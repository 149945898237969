import axios, { AxiosInstance, AxiosResponse } from "axios";
import { IJIRAIssue, IOptions, IProjectFilter, IProjectsResponse, IPSRForm } from "../models/psr.model";

const isLocalEnv = window.location.host.includes("localhost");
const apiUrl = isLocalEnv ? "http://localhost:8082/api" : "https://puma.ea.adobe.net/api";
// const token: any = localStorage.getItem("authToken");
// const accessToken = token ? JSON.parse(token).token : null

const adminUrl = `${apiUrl}/admin`;

const Axios = axios.create({
  baseURL: apiUrl,
  // headers: { Authorization: "Bearer " + accessToken },
});

const adminAxios = axios.create({
  baseURL: adminUrl,
  // headers: { Authorization: "Bearer " + accessToken },
});

Axios.interceptors.response.use(
  response => response,
  error => {
    console.log(error.message);
    return Promise.reject(error);
  }
)

function getRequestConfig() {
  const token: any = localStorage.getItem("authToken");
  const accessToken = token ? JSON.parse(token).token : null
  return {
    headers: { Authorization: "Bearer " + accessToken },
  };
}

const getDBProfileDetails = async (email) => {
  const config = getRequestConfig()
  console.log("email", email);
  return await Axios.get(`/profile?email=${email}`, config);
};

const fetchAll = (route, pageSize, currentPage) => {
  const config = getRequestConfig()
  if (pageSize) {
    //it is for pagination
    return Axios.get(`${route}/${currentPage}?PageSize=${pageSize}`, config);
  } else {
    // it is for admin
    console.log("called");
    return Axios.get(`${route}`, config);
  }
};

const fetchAllOptions = async (): Promise<IOptions> => {
  const config = getRequestConfig()
  const response: AxiosResponse = await Axios.get(`options`, config)
  return response.data
}

const fetchAllProjects = async (pageSize = 10, currentPage = 1) => {
  console.log("Fetching all projects");
  const config = getRequestConfig()
  const response: AxiosResponse = await Axios.get(`projects?PageSize=${pageSize}&page=${currentPage}`, config)
  return response.data
};

const fetchProjectById = async (id: number) => {
  console.log("Fetching project by id: " + id);
  const config = getRequestConfig()
  const response: AxiosResponse = await Axios.get(`projects/${id}`, config)
  return response.data
};

const deleteById = (route: string, id: number, table: string) => {
  const config = getRequestConfig()
  return adminAxios.delete(`${route}?value=${id}&&table=${table}`, config);
};

const updateProject = (formData) => {
  // if (table) {
  // it is admin
  // return adminAxios.put('project', {
  // name: formData,
  //     table: table,
  //     id: id,
  //   });
  // } else {
  const config = getRequestConfig()
  return Axios.put('projects', formData, config);
};

const createProject = async (formData: IPSRForm) => {
  const config = getRequestConfig()
  const response: AxiosResponse = await Axios.post("projects", formData, config);
  return response.data
};

const createNew = async (route, formData, table) => {
  const config = getRequestConfig()
  if (table) {
    return await adminAxios.post(route, {
      name: formData,
      table: table,
    }, config);
  } else {
    const response = await adminAxios.post(route, { name: formData }, config);
    return response.data;
  }
};

const createJIRA = async (jiraData: IJIRAIssue) => {
  const config = getRequestConfig()
  const response = await Axios.post(`jira/create`, jiraData, config)
  return response.data;
}

const addEngineer = async (formData) => {
  const config = getRequestConfig()
  const response = await adminAxios.post('engineer', {
    name: formData.name,
    ldap: formData.ldap
  }, config)
  return response.data
}

const addCompany = async (formData) => {
  const config = getRequestConfig()
  const response = await adminAxios.post('company', {
    companyName: formData.companyName,
  }, config)
  return response.data
}

const filterSearch = async (columnFilter: IProjectFilter, pageSize: number, currentPage = 1): Promise<IProjectsResponse> => {
  console.log("Fetching search for ", columnFilter, pageSize, currentPage);
  const config = getRequestConfig()
  const res: AxiosResponse = await Axios.post(`filter`, { data: columnFilter, pageSize: pageSize, page: currentPage }, config);
  console.log(res.data);
  return res.data;
};

async function globalSearch(searchTerm: string): Promise<IProjectsResponse> {
  const config = getRequestConfig()
  return Axios.get(`search?query=${searchTerm}`, config).then((res) => res.data);
}

const api = {
  fetchAll,
  fetchAllOptions,
  fetchAllProjects,
  fetchProjectById,
  deleteById,
  updateProject,
  createProject,
  createNew,
  getDBProfileDetails,
  globalSearch,
  filterSearch,
  addEngineer,
  addCompany,
  createJIRA
};

export default api;
