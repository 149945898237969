export const PSRSchema = {
    "type": "object",
    "properties": {
        "process_id": {
            "type": ["string", "null"]
        },
        "process_name": {
            "type": ["string"]
        },
        "region": {
            "type": ["string", "null"]
        },
        "engineer_name": {
            "type": ["string", "null"]
        },
        "server_name": {
            "type": ["string", "null"]
        },
        "data_center": {
            "type": ["string", "null"]
        },
        "client_id": {
            "type": ["string", "null"]
        },
        "client_name": {
            "type": ["string", "null"]
        },
        "classification_name": {
            "type": ["string", "null"]
        },
        "classification_id": {
            "type": ["string", "null"]
        },
        "enabled": {
            "type": "boolean"
        },
        "process_summary": {
            "type": ["string", "null"]
        },
        "rsids": {
            "type": "array",
            "items": {}
        },
        "primaryEngineer_id": {
            "type": ["string", "number", "null"]
        },
        "secondaryEngineer_id": {
            "type": ["string",  "number", "null"]
        },
        "manager_id": {
            "type": ["string",  "number", "null"]
        },
        "teamLead_id": {
            "type": ["string",  "number", "null"]
        },
    },
    "required": [
        "process_id",
        "process_name",
        "region",
        "client_id",
        "enabled",
        "process_summary"
    ]
}