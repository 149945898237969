import React, { useContext, useState } from "react";
import { ActionButton, Button as ButtonV3, Content, Dialog, DialogTrigger, Divider, Flex, View } from "@adobe/react-spectrum";
import "./TopNavBar.css";
import { PickerContext } from "../../contexts/GlobalState";
import { useHistory, useRouteMatch } from "react-router-dom";
import RealTimeCustomerProfile from "@spectrum-icons/workflow/RealTimeCustomerProfile";
import { IMSContext } from "../../contexts/IMSContext";



const tabs = [
    { name: "Home", url: "/" },
    { name: "Ant", url: "/ant" },
    // { name: "Cost", url: "/cost" },
];

let serviceId = "7b09fc61-2f3d-43d0-9b12-f51027da7d02";

interface User {
    PumaUser: string;
    pumaUserFullName: string;
    pumaUserEmail: string;
    pumaUserDefaultRole: string;
}


const TopNavBar: React.FC = () => {
    const values = useContext(PickerContext);
    const history = useHistory();
    const { isAdmin, isEngineer } = useContext(IMSContext)
    const [tabSelected, setTabSelected] = useState(tabs[0]?.name);
    let [name, setName] = useState(" ");
    let [acctype, setAccType] = useState(" ");
    let [email, setEmail] = useState(" ");


    window.adobeIMS.getProfile().then((response: any) => {
        setName(JSON.stringify(response.displayName).slice(1, -1));
        setEmail(JSON.stringify(response.email).slice(1, -1));
        setAccType(JSON.stringify(response.account_type).slice(1, -1));
    });

    const logoutCall = () => {
        window.adobeIMS.signOut();
        localStorage.removeItem("authToken");
    };

    return (
        <div
            className="Puma-topNavBar-root-div"
            id="Puma-topNavBar-root-div"
            style={{ isolation: "auto", position: "sticky" }}
        >
            <header
                className="spectrum-Shell spectrum--darkest"
                style={{
                    backgroundColor: "#323232",
                    color: "white",
                    minHeight: "48px",
                    maxHeight: "48px",
                    zIndex: 1,
                }}
            >
                <div className="spectrum-Shell-leftContainer">
                    <div
                        tabIndex={0}
                        role="button"
                        className="spectrum-Shell-logoIcon"
                        onClick={() => {
                        }}
                    >
                        {/* <img src={pumalogo} /> */}
                        <div className="spectrum-Shell-logoLabel">PUMA</div>
                    </div>
                    <div className="spectrum-Shell-workspace-container">
                        <div
                            role="tablist"
                            className="spectrum-Tabs spectrum-Tabs--horizontal spectrum-Tabs--quiet spectrum-Shell-workspace spectrum-Shell-workspace--show"
                            aria-orientation="horizontal"
                        >
                            {tabs.map((tab: any) => {
                                return (
                                    <div
                                        key={tab.name}
                                        className={
                                            "spectrum-Tabs-item " +
                                            (tabSelected === tab.name ? "is-selected" : "")
                                        }
                                        id="react-spectrum-1"
                                        role="tab"
                                        aria-selected={tabSelected === tab.name ? "true" : "false"}
                                        tabIndex={tabSelected === tab.name ? 0 : -1}
                                        onClick={() => {
                                            setTabSelected(tab.name);
                                            history.push(tab.url);
                                        }}
                                    >
                                        <span className="spectrum-Tabs-itemLabel">{tab.name}</span>
                                        {tabSelected === tab.name ? (
                                            <div
                                                className="spectrum-Tabs-selectionIndicator spectrum-Tabs-selectionIndicator-animation"
                                                role="presentation"
                                            ></div>
                                        ) : null}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="spectrum-Shell-rightContainer">
                    <DialogTrigger type="popover" key="profile" hideArrow={true}>
                        <ActionButton
                            id="topNavBar-profile-button"
                            isQuiet
                            aria-label="Icon only"
                            UNSAFE_className="topBar-Avatar-Button"
                            UNSAFE_style={{ cursor: "pointer", border: "none" }}
                        >
                            {/* <img
                                className="topBar-avatar spectrum-Avatar"
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = "";
                                }}
                                alt={`${email.replace('@adobe.com', '')}`}
                                src='#'
                            /> */}
                            <RealTimeCustomerProfile />
                        </ActionButton>
                        <Dialog
                            size="L"
                            // width="auto" 
                            height="auto"
                            minWidth="400px"
                            width="fit-content"
                        >
                            <Content>
                                <div style={{ marginTop: "0px" }}>
                                    <Flex direction="row" gap="size-100">
                                        <View width="size-700">
                                            {/* <img
                                                className="topBar-avatar-big spectrum-Avatar"
                                                onError={({ currentTarget }) => {
                                                    currentTarget.onerror = null; // prevents looping
                                                    currentTarget.src = "";
                                                }}
                                                alt="true"
                                                // src={`https://s7d2.scene7.com/is/image/IMGDIR/${email.replace('@adobe.com', '')}`}
                                                src="#"
                                            /> */}
                                            <RealTimeCustomerProfile />
                                        </View>
                                        <View
                                            flex
                                            UNSAFE_style={{ marginTop: "-18px", marginLeft: "10px" }}
                                        >
                                            <div
                                                className="topBar-Menu-item-Username-gnvb44"
                                                style={{ marginTop: "15px", fontWeight: "bold" }}
                                            >
                                                {name}
                                            </div>
                                            <div
                                                className="topBar-Menu-item-UserEmail-gnvb44"
                                                style={{
                                                    marginTop: "0px",
                                                    fontWeight: "bold",
                                                    color: "gray",
                                                }}
                                            >
                                                {email}
                                            </div>
                                            <div
                                                className="topBar-Menu-item-UserEmail-gnvb44"
                                                style={{
                                                    marginTop: "10px",
                                                    fontWeight: "bold",
                                                    color: "gray",
                                                    marginBottom: "10px",
                                                }}
                                            >
                                                <p> 
                                                    {isAdmin? "admin" : isEngineer? "engineer": ""}
                                                </p>
                                            </div>
                                        </View>
                                    </Flex>
                                    <Divider size="S" />
                                    <div
                                        className="topBar-Menu-item-UserProfile-gnvb44"
                                        style={{
                                            marginTop: "10px",
                                            fontWeight: "bold",
                                            marginBottom: "10px",
                                        }}
                                        onClick={() => {
                                            document
                                                .getElementById("topNavBar-profile-button")
                                                ?.click();
                                            // setShowUserPrefDialog(true);
                                        }}
                                    >
                                        Documentation
                                    </div>
                                    <div
                                        className="Glaas-topNavbar-heading-level-6"
                                        style={{
                                            marginTop: "20px",
                                            color: "#adadad",
                                            marginBottom: "10px",
                                        }}
                                    >
                                        PUMA SUPPORT
                                    </div>
                                    <span
                                        onClick={() => {
                                            window.location.href = "mailto:GLaaS-Support@adobe.com";
                                        }}
                                    >
                                        <div
                                            className="topBar-Menu-item-UserProfile-gnvb44"
                                            style={{
                                                marginTop: "10px",
                                                fontWeight: "bold",
                                                marginBottom: "10px",
                                            }}
                                        >
                                            Contact Team
                                        </div>
                                    </span>
                                    <span>
                                        <div
                                            className="topBar-Menu-item-UserProfile-gnvb44"
                                            style={{
                                                marginTop: "10px",
                                                fontWeight: "bold",
                                                marginBottom: "10px",
                                            }}
                                        >
                                            Send Feedback
                                        </div>
                                    </span>
                                    <Divider size="S" />
                                    <br />
                                    <ButtonV3
                                        variant="cta"
                                        onPress={() => {
                                            logoutCall();
                                        }}
                                    >
                                        Sign Out
                                    </ButtonV3>
                                </div>
                            </Content>
                        </Dialog>
                    </DialogTrigger>
                </div>
            </header>
        </div>

    );
};

export default TopNavBar;