import { Item, TabList, TabPanels, Tabs, View } from "@adobe/react-spectrum";
import React from "react";
import Company from "../../../components/Administration/Company";
import Engineer from "../../../components/Administration/Engineer";
import ProjectType from "../../../components/Administration/ProjectType";
import Accounts from "../../../components/Administration/Accounts";
// import Servers from "../../../components/Administration/Servers";

function AdminPage() {

  return (
    <View marginTop="size-700" width="95%" marginStart="size-200">
      <h3>Admin Menu</h3>
      <br />
      <Tabs aria-label="History of Ancient Rome">
        <TabList flex="1 1 auto" minWidth="0px">
          <Item key="com">Company</Item>
          <Item key="pct">Project Classification</Item>
          <Item key="eng">Engineers</Item>
          <Item key="account">Accounts</Item>
          {/* <Item key="ser">Servers</Item> */}
        </TabList>

        <TabPanels>
          <Item key="com">
            <Company />
          </Item>
          <Item key="pct">
            <ProjectType />
          </Item>
          <Item key="eng">
            <Engineer />
          </Item>
          <Item key="account">
            <Accounts />
          </Item>
          {/* <Item key="ser">
            <Servers />
          </Item> */}
        </TabPanels>
      </Tabs>
    </View>
  );
}

export default AdminPage;
