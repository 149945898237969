import {
  ComboBox, Form,
  Item, TextField
} from "@adobe/react-spectrum";
import React, { useContext, useState } from "react";
import { PickerContext } from "../../../contexts/GlobalState";
import { IPSRForm } from "../../../models/psr.model";

interface IPSRFormProp {
  formData: IPSRForm
  setFormData: any
  onClickNext: any
  onClickPrev: any
  formView: any
  type: any
}

const Assignees = (props: IPSRFormProp) => {
  //testing purpose picker options
  const { defaultData } = useContext(PickerContext);;
  const prev = () => onClickPrev(4);
  const next = () => onClickNext(6);
  const {
    formData,
    setFormData,
    onClickNext,
    onClickPrev,
    formView,
    type,
    // defaultData,
  } = props
  let options = [
    { id: 1, name: 'Adobe Photoshop' },
    { id: 2, name: 'Adobe XD' },
    { id: 3, name: 'Adobe InDesign' },
    { id: 4, name: 'Adobe AfterEffects' },
    { id: 5, name: 'Adobe Illustrator' },
    { id: 6, name: 'Adobe Lightroom' },
    { id: 7, name: 'Adobe Premiere Pro' },
    { id: 8, name: 'Adobe Fresco' },
    { id: 9, name: 'Adobe Dreamweaver' }
  ];
  let [productId, setProductId] = React.useState<number>(9);

  return (
    <>
      <br />
      {/* <p>
        {JSON.stringify(defaultData.engineers)}
      </p> */}
      {defaultData && formView.isReadOnly ? (
        //"view" mode render
        <>
          <Form
            aria-label="Get PSR assignees"
            width="60%"
            labelPosition="side"
            labelAlign="start"
            isQuiet
            isReadOnly
          >
            {
              formData?.teamLead_id ? <TextField label="Team Lead" value={defaultData.engineers.find(obj => obj.id === formData?.teamLead_id).name} /> : <TextField label="Team Lead" value="" />
            }
            {
              formData?.manager_id ? <TextField label="Manager" value={defaultData.engineers.find(obj => obj.id === formData?.manager_id).name} /> : <TextField label="Manager" value="" />
            }
            {
              formData?.primaryEngineer_id ? <TextField label="Primary Engineer" value={defaultData.engineers.find(obj => obj.id === formData?.primaryEngineer_id).name} /> : <TextField label="Primary Engineer" value="" />
            }
            {
              formData?.secondaryEngineer_id ? <TextField label="Secondary Engineer" value={defaultData.engineers.find(obj => obj.id === formData?.secondaryEngineer_id).name} /> : <TextField label="Secondary Engineer" value="" />
            }
          </Form>
        </>
      ) : (
        // "add" or 'edit" mode render
        <>
          {defaultData && formData ? (
            <Form
              aria-label="Get PSR assignees"
              width="60%"
              labelPosition="top"
              labelAlign="start"
              marginStart="size-1200"
              necessityIndicator="icon"
              marginTop="2%"
            >
              <ComboBox
                label="Manager"
                defaultItems={defaultData.engineers}
                selectedKey={formData.manager_id}
                onSelectionChange={selected => {
                  setFormData({ ...formData, manager_id: +selected });
                }}>
                {/* @ts-ignore */}
                {item => <Item>{item.name}</Item>}
              </ComboBox>

              <ComboBox
                label="Team Lead"
                defaultItems={defaultData.engineers}
                selectedKey={formData.teamLead_id}
                onSelectionChange={selected => {
                  setFormData({ ...formData, teamLead_id: +selected });
                }}>
                {/* @ts-ignore */}
                {item => <Item>{item.name}</Item>}
              </ComboBox>

              <ComboBox
                label="Owner"
                defaultItems={defaultData.engineers}
                selectedKey={formData.owner_id}
                onSelectionChange={selected => {
                  setFormData({ ...formData, owner_id: +selected });
                }}>
                {/* @ts-ignore */}
                {item => <Item>{item.name}</Item>}
              </ComboBox>

              <ComboBox
                label="Primary Engineer"
                defaultItems={defaultData.engineers}
                selectedKey={formData.primaryEngineer_id}
                onSelectionChange={selected => {
                  setFormData({ ...formData, primaryEngineer_id: +selected });
                }}>
                {/* @ts-ignore */}
                {item => <Item>{item.name}</Item>}
              </ComboBox>

              <ComboBox
                label="Secondary Engineer"
                defaultItems={defaultData.engineers}
                selectedKey={formData.secondaryEngineer_id}
                onSelectionChange={(selected) => {
                  setFormData({ ...formData, secondaryEngineer_id: +selected });
                }}
              >
                {(item: any) => <Item key={item.id}>{item.name}</Item>}
              </ComboBox>

            </Form>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default Assignees;
