import React, { useContext, useState } from "react";
import {
  TableView,
  TableHeader,
  TableBody,
  Column,
  Row,
  Cell,
  Form,
  TextField,
  Flex,
  Button
} from "@adobe/react-spectrum";
import { PickerContext } from "../../../contexts/GlobalState";
import { IPSRForm } from "../../../models/psr.model";

interface IPSRFormProp {
  formData: IPSRForm; // Single project object containing account_info
  setFormData: any;
  formView: any;
}

const Accounting = (props: IPSRFormProp) => {
  const { formData, formView, setFormData } = props;
  const { defaultData } = useContext(PickerContext);

  let [isValid, setValid] = useState(false);
  let [validDR, setvalidDR] = useState(false);
  let [validpsa, setvalidpsa] = useState(false);
  // Check if account_info exists and is an array
  const accountInfo = formData?.account_info || [];
  const validDr = (value) => {
    const regex = /^(DR)([0-9]+)$/.test(value);
    setvalidDR(value.length <= 9 && regex ? true : false);
    setFormData({ ...formData, dr_number: value });
  };

  const validLink = (value) => {
    const regex =
      /^([A-Za-z0-9]+@|http(|s)\:\/\/)([A-Za-z0-9.]+([A-za-z0-9])?(:\d+)?)(?::|\/)([=&?\d\/\w._-]+?)(\.adobe.my.saleforce.com)?$/i.test(
        value
      );
    setvalidpsa(value.length <= 255 && regex ? true : false);
    setFormData({ ...formData, psa_link: value });
  };

  const onChange = (newVal) => {
    const regex = /^\d*(\.\d{1,2})?$/.test(newVal);
    setValid(newVal.length <= 13 && regex ? true : false);
    setFormData({ ...formData, annual_recurring_revenue: newVal });
  };

  const columns = [
    { key: "DR", name: "DR" },
    { key: "year", name: "Year" },
    { key: "start_date", name: "Start Date" },
    { key: "end_date", name: "End Date" },
    { key: "link", name: "Link" }
  ];

  return (
    <>
      <Flex direction="column" gap="size-1000">


        {defaultData && formData && (
          <Form
            aria-label="Get PSR accounting"
            width="60%"
            labelPosition="top"
            labelAlign="start"
            marginStart="20%"
            necessityIndicator="icon"
            isReadOnly={formView.isReadOnly ? true : false}
          >
            {/* <TextField
            label="PSA DR"
            maxLength={9}
            value={formData.dr_number}
            // validationState={validDR ? "valid" : "invalid"}
            onChange={validDr}
          />

          <TextField
            label="PSA Link"
            value={formData.psa_link}
            // validationState={validpsa ? "valid" : "invalid"}
            onChange={validLink}
          /> */}

            <TextField
              label="ACM"
              maxLength={16}
              value={formData.acm}
              onChange={(value) => {
                setFormData({ ...formData, acm: value });
              }}
            />

            <TextField
              label="Revenue"
              maxLength={13}
              value={formData.annual_recurring_revenue}
              // validationState={isValid ? "valid" : "invalid"}
              onChange={onChange}
            />
          </Form>
        )}
        {defaultData && accountInfo.length > 0 && (
          <TableView
            aria-label="Accounting Information"
            selectionMode="none"
            width="80%"
            marginX="auto"
          // isReadOnly={formView.isReadOnly}
          >
            <TableHeader>
              {columns.map((column) => (
                <Column key={column.key}>{column.name}</Column>
              ))}
            </TableHeader>
            <TableBody>
              {accountInfo.map((data, index) => (
                <Row key={index}>
                  <Cell>{data.DR || "-"}</Cell>
                  <Cell>{data.year || "-"}</Cell>
                  <Cell>{data.start_date || "-"}</Cell>
                  <Cell>{data.end_date || "-"}</Cell>
                  <Cell><Button variant="primary" onPress={() => window.open(data.link, "_blank")} UNSAFE_style={{ cursor: "pointer", border: "none" }}>Link</Button></Cell>
                </Row>
              ))}
            </TableBody>
          </TableView>
        )}
        {defaultData && accountInfo.length === 0 && (
          <p style={{ textAlign: "center", marginTop: "20px" }}>
            No accounting data available.
          </p>
        )}
      </Flex>
    </>
  );
};

export default Accounting;
